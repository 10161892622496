import React, { useState } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import styled from 'styled-components'
import ProgressiveImage from 'react-progressive-image'
import { motion } from 'framer-motion'
import { Parallax } from 'react-scroll-parallax';
import { Layout } from '../components'

import { container, padding, bgImage, parallaxProps } from '../styles/global'
import { heading1, heading2, links } from '../styles/type'

import logoLight from '../assets/images/logos/desktop/logo-light.svg'
import logoDark from '../assets/images/logos/desktop/logo-dark.svg'
import { brown1, brown2, brown3, brown4, blue1 } from '../styles/colors'
import { Fade } from '../utils'
import { media, useBreakpoint } from '../styles/utils'
import { isIterable } from 'iterall'

const Index = props => {
    const graphData = useStaticQuery(query).allWordpressInfopages.nodes[0]
    const data = JSON.parse(graphData.acf_json)

    const renderHeroImage = () => {
        return (
            <Hero>
                <Parallax
                    {...parallaxProps}
                >
                    <Image
                        key={data.hero_image.ID}
                        src={data.hero_image.sizes && data.hero_image.sizes.medium2}
                    >
                        {(src, loading) => {
                            return (
                                <BGImage
                                    image={src}
                                    style={{ opacity: loading ? 1 : 1 }}
                                />
                            )
                        }}
                    </Image>
                </Parallax>

                <Fade delay={200}>
                    <Description>
                        <Logo src={logoDark} />
                        <Heading>Kardos Scanlan</Heading>
                    </Description>
                </Fade>
            </Hero>
        )
    }

    const renderIntroLinks = () => {
        const items = data.links.map((item, i) => {
            return (
                <IntroLink key={i} to={item.link.url}>
                    {item.link.title}
                </IntroLink>
            )
        })

        return <IntroLinks>{items}</IntroLinks>
    }

    const renderIntro = () => {
        return (
            <OurPurpose>
                <Fade delay={500}>
                    <Container>
                        <Grid>
                            <Heading
                                dangerouslySetInnerHTML={{
                                    __html: data.intro_heading,
                                }}
                            />
                            <Description>
                                <Info
                                    dangerouslySetInnerHTML={{
                                        __html: data.intro_text,
                                    }}
                                />
                                {renderIntroLinks()}
                            </Description>
                        </Grid>
                    </Container>
                </Fade>
            </OurPurpose>
        )
    }

    const renderExpertise = () => {
        const items = data.services.map((item, i) => {
            return (
                <ServiceLink key={i} to={`/service/${item.slug}`}>
                    <Fade
                        delay={100 * i}
                    >
                        <ServiceTransition key={item.image.ID} whileHover={'hover'}>
                            <ImageWrapper>
                                <Image
                                    key={item.image.ID}
                                    src={item.image.sizes.medium}
                                >
                                    {(src, loading) => {
                                        return (
                                            <ServiceBGImage
                                                image={src}
                                                key={item.image.ID}
                                                {...animatedImage}
                                            />
                                        )
                                    }}
                                </Image>
                            </ImageWrapper>
                            <ServiceDescription
                                key={item.image.ID}
                                {...animatedDescription}
                            >
                                <ServiceLabel
                                    key={item.image.ID}
                                    {...animatedLabel}
                                >
                                    {item.title}
                                </ServiceLabel>
                            </ServiceDescription>
                        </ServiceTransition>
                        </Fade>
                </ServiceLink>
            )
        })

        return (
            <Expertise>
                <Fade
                    delay={600}
                >
                    <Container>
                        <Heading
                            dangerouslySetInnerHTML={{
                                __html: data.services_heading,
                            }}
                        />
                        <Services>{items}</Services>
                    </Container>
                </Fade>
            </Expertise>
        )
    }

    const renderAbout = () => {
        return (
            <About>
                <Fade>
                    <Container>
                        <Grid>
                            <Heading
                                dangerouslySetInnerHTML={{
                                    __html: data.about_heading,
                                }}
                            />
                            <Description>
                                <Info
                                    dangerouslySetInnerHTML={{
                                        __html: data.about_text,
                                    }}
                                />
                                {data.about_use_link && (
                                    <AboutLink to={data.about_link.url}>
                                        {data.about_link.title}
                                    </AboutLink>
                                )}
                            </Description>
                        </Grid>
                    </Container>
                </Fade>
            </About>
        )
    }

    return (
        <Layout
            meta={data && data.seo}
            mobileHeaderTheme={'light'}
        >
            {renderHeroImage()}
            {renderIntro()}
            {renderExpertise()}
            {renderAbout()}
        </Layout>
    )
}

// Shared

const Heading = styled.h1``
const Subheading = styled.h2``
const Description = styled.div``
const Info = styled.div``

const Image = styled(ProgressiveImage)`
    overflow: hidden;
`

const BGImage = styled.div`
    background-image: url(${props => props.image});
    ${bgImage};
    transition: opacity 0.45s ease;
`

// Layout

const Wrapper = styled.div``
const Container = styled.div`
    ${container}
    ${padding}
`
const Grid = styled.div``

// Hero Section

const Logo = styled.img``
const Hero = styled.div`
    position: relative;
    height: 880px;
    overflow: hidden;

    ${media.tabletLandscape`
        height: 100vh;
    `}

    ${Description} {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    ${Logo} {
        width: 60px;
        height: auto;
        margin-top: -100px;

        ${media.phone`
            width: 40px;
            margin-top: -60px;
        `}
    }

    ${Heading} {
        text-transform: uppercase;
        margin-top: 42px;
        font-family: Basis Grotesque;
        font-weight: 700;
        font-size: 40px;
        line-height: 1;
        letter-spacing: 0.4em;
        color: white;

        ${media.phone`
            margin-top: 24px;
            font-size: 22px;
        `}
    }

    ${Image},
    ${BGImage} {
        height: 100%;
    }

    .parallax-outer {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: black;
    }

    ${BGImage} {
        height: 1100px;

        ${media.tabletLandscape`
            height: 120vh;
        `}
    }

    .react-reveal {
        height: 100%;
    }
`

// Our Purpose Section

const IntroLinks = styled.div``
const IntroLink = styled(Link)``
const OurPurpose = styled.section`
    width: 100%;
    padding-top: 140px;
    padding-bottom: 110px;
    background-color: ${brown3};

    ${media.tabletLandscape`
        padding-top: 110px;
        padding-bottom: 120px;
    `}

    ${media.phone`
        padding-top: 48px;
        padding-bottom: 64px;
        background-color: white;
    `}

    ${Grid} {
        display: flex;
        margin: 0 -20px;

        ${media.phone`
            display: block;
            margin: 0;
        `}
    }

    ${Heading}, 
    ${Description} {
        width: 50%;
        padding: 0 20px;
        box-sizing: border-box;

        ${media.phone`
            width: 100%;
            padding: 0;
        `}
    }

    ${Heading} {
        ${heading1};
        transform: translateY(-0.2em);

        ${media.phone`
            transform: none;
        `}
    }

    ${Info} {
        ${media.phone`
            margin-top: 80px;
        `}

        * {
            ${heading2};
        }

        strong {
            font-weight: 600;
        }
    }

    ${IntroLinks} {
        display: flex;
        margin-top: 40px;

        ${media.phone`
            margin-top: 150px;
        `}
    }

    ${IntroLink} {
        margin-right: 40px;
        ${links};
        transition: border-color 0.15s ease, color 0.15s ease;

        &:hover {
            color: ${brown1};
            border-color: ${brown1};
        }

        &:last-child {
            margin-right: 0;
        }

        ${media.phone`
            margin-right: 20px;
        `}
    }
`

// Expertise Grid Layout

const Services = styled.div``
const Expertise = styled.section`
    width: 100%;
    padding-top: 120px;
    padding-bottom: 180px;
    background-color: ${brown4};

    ${media.tabletLandscape`
        padding-top: 110px;
        padding-bottom: 120px;
    `}

    ${media.phone`
        padding-top: 48px;
        padding-bottom: 60px;
    `}

    ${Heading} {
        ${heading1};
    }

    ${Services} {
        display: flex;
        flex-flow: row wrap;
        margin: 80px -10px -64px;

        ${media.tabletLandscape`
            margin: 80px -10px -64px;
        `}

        ${media.phone`
            margin: 40px -5px -30px;
        `}
    }
`

// Expertise Grid Items

const ImageWrapper = styled.div``
const ServiceBGImage = styled(motion.div)`
    background-image: url(${props => props.image});
    ${bgImage};
    height: 100%;
`
const ServiceTransition = styled(motion.div)`
    height: 100%;
`
const ServiceDescription = styled(motion.div)``
const ServiceLabel = styled(motion.div)``
const ServiceLink = styled(Link)`
    display: inline-block;
    width: 25%;
    margin-bottom: 64px;
    padding: 0 10px;
    box-sizing: border-box;
    overflow: hidden;

    ${media.phone`
        width: 50%;
        padding: 0 5px;
        margin-bottom: 30px;
    `}

    ${ImageWrapper} {
        overflow: hidden;
        height: 200px;
        background-color: black;

        ${media.tablet`
            height: 150px;
        `}

        ${media.phone`
            height: 120px;
        `}
    }

    ${ServiceDescription} {
        border-bottom-width: 1px;
        border-bottom-style: solid;
        border-bottom-color: ${brown1};

        ${media.phone`
            left: 0;
            right: 0;
            display: block;
        `}

        ${ServiceLabel} {
            padding: 16px 0;
            background-color: ${brown4};
            border-bottom-color: ${brown1};
            color: ${brown1};
            font-family: Basis Grotesque;
            font-weight: 700;
            font-size: 16px;
            line-height: 1;

            ${media.tabletLandscape`
                padding: 12px 0;
                font-size: 12px;
            `}
        }
    }
`

// Expertise Grid Animations

const animatedImage = {
    initial: {
        scale: 1.1,
        opacity: 1,
    },
    variants: {
        hover: {
            scale: 1.01,
            opacity: 0.8,
        },
    },
    transition: { duration: 0.8 },
}

const animatedDescription = {
    initial: {
        borderBottomColor: `${brown1}`,
    },
    variants: {
        hover: {
            borderBottomColor: `${brown1}`,
        },
    },
    transition: { duration: 0.3 },
}

const animatedLabel = {
    initial: {
        y: 0,
        color: `${brown1}`,
    },
    variants: {
        hover: {
            y: '-8px',
            color: `${brown1}`,
        },
    },
    transition: { duration: 0.3 },
}


// About Section

const AboutLink = styled(Link)``
const About = styled.section`
    width: 100%;
    padding-top: 150px;
    padding-bottom: 110px;
    background-color: ${brown3};

    ${media.tabletLandscape`
        padding-top: 110px;
        padding-bottom: 120px;
    `}

    ${media.phone`
        padding-top: 48px;
        padding-bottom: 90px;
    `}

    ${Grid} {
        display: flex;
        margin: 0 -20px;

        ${media.phone`
            display: block;
            margin: 0;
        `}
    }

    ${Heading}, ${Description} {
        width: 50%;
        padding: 0 20px;
        box-sizing: border-box;

        ${media.phone`
            width: 100%;
            padding: 0;
        `}
    }

    ${Heading} {
        ${heading1};
        transform: translateY(-0.2em);

        ${media.phone`
            transform: none;
        `}
    }

    ${Info} {
        ${media.phone`
            margin-top: 80px;
        `}

        * {
            ${heading2};
        }

        strong {
            font-weight: 600;
        }
    }

    ${AboutLink} {
        ${links};
        transition: border-color 0.15s ease, color 0.15s ease;

        &:hover {
            color: ${brown1};
            border-color: ${brown1};
        }

        ${media.phone`
            margin-top: 8px;
        `}
    }
`

export const query = graphql`
    query {
        allWordpressInfopages(filter: { slug: { eq: "home" } }) {
            nodes {
                acf_json
            }
        }
    }
`

export default Index
